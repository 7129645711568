import React from "react";
import AreaList from "../components/AreaList";
import { graphql } from "gatsby";
import Header from "../components/Header";
import { Helmet } from "react-helmet"
import { NewsItemsList } from "./news";

const IndexPage = ({ data }) => {
  console.log("INDEX GOT DATA:", data)
  return (
    <main className="flex flex-col w-full h-full bg-white text-black border-t-4 border-pink-200 overflow-x-clip">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dana Calacci</title>
      </Helmet>
      <div className="flex flex-col h-full items-center">
        <Header />
        <div className="flex flex-col w-full xl:w-2/3">
          <div className="flex flex-col justify-center items-center sm:flex-nowrap md:p-5 ">
            <AreaList areas={data.areas.nodes} areaItems={data.areaItems.nodes} />
          </div>
        </div>
        <NewsItemsList items={data.newsItems.nodes} />
      </div>
    </main>
  );
};

export const query = graphql`
query AreasItemsQuery {
    areas:allMdx(
      filter: { frontmatter: { templateKey: { eq: "areas" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      nodes {
        id
        frontmatter {
          summary
          title
          templateKey
          }
        slug
      }
    }
    areaItems:allMdx(
    filter: { frontmatter: { templateKey: { eq: "area-items" }} }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      nodes {
        id
        frontmatter {
          title
          description
          area
          icon
          hasContent
          url
        }
        body
        slug
      }
    }
    newsItems:allMdx(
      filter: { frontmatter: { templateKey: { in: ["pubs-and-talks"] } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        id
        body
        slug
        frontmatter {
          title
          authors
          venue
          url
          type
          description
          date(formatString: "YYYY")
          file {
            publicURL
            }
          notes {
            title
            url
          }
        }
      }
    }
  }
`;


export default IndexPage;
